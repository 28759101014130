<template>
  <div
    v-if="isCurrentUserHost"
  >
    <b-card>
      <template #header>
        <h4 class="card-title">
          Internal note
        </h4>
        <b-button-group>
          <b-button
            v-if="asset.internalNote !== internalNoteUpdate"
            size="sm"
            @click="resetInternalNote"
          >
            Cancel
          </b-button>
          <b-button
            size="sm"
            variant="primary"
            @click="saveInternalNote"
          >
            Save
          </b-button>
        </b-button-group>
      </template>
      <quill-editor
        v-model="internalNoteUpdate"
      />
    </b-card>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

import { BCard, BButtonGroup, BButton } from 'bootstrap-vue'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

import AssetService from '@/service/asset.service'

export default {
  components: {
    BCard,
    BButtonGroup,
    BButton,
    quillEditor,
  },
  props: {
    asset: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      internalNoteUpdate: null,
    }
  },
  computed: {
    ...mapGetters({
      isCurrentUserHost: 'auth/isHost',
    }),
  },
  created() {
    this.internalNoteUpdate = this.asset.internalNote
  },
  methods: {
    resetInternalNote() {
      // Reset internal note to original internal note of asset
      this.internalNoteUpdate = this.asset.internalNote
    },
    saveInternalNote() {
      AssetService.updateAsync(this.asset.id,
        {
          internalNotes: this.internalNoteUpdate,
          primarySupportContactId: (this.asset.primarySupportContact || {}).id,
        },
        { disableTenantFilter: true })
        .then(() => {
          this.$emit('internalNoteUpdate', {
            internalNote: this.internalNoteUpdate,
          })
          this.$toast.success('Updated!', {
            icon: true,
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
